import React from "react"
import Layout from "../../components/layout"
import BackBtn from "../../components/BackBtn"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export default function documentaires({ data }) {
  return (
    <Layout>
      <main className="main-content main-documentaires">
        <div className="title-container">
          <h1 className="main-title">Afscheid</h1>
          <BackBtn path="../" text="Terug naar documentaires"></BackBtn>
        </div>
        <p className="main-description">
          De titel spreekt voor zich. Afscheid nemen van een dierbare.
        </p>
        <div className="photo-carousel">
          <Carousel>
            {data.afscheid.edges.map(image => {
              return (
                <Carousel.Item key={image.node.id} interval={5000}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    alt={image.node.base.split("-").join(" ").split(".")[0]}
                  />
                  <Carousel.Caption>
                    <p className="banner-text">
                      {image.node.base.split("-").join(" ").split(".")[0]}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    afscheid: allFile(
      filter: { relativeDirectory: { eq: "documentaires/afscheid" } }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid(
              maxWidth: 600
              maxHeight: 400
              fit: COVER
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
